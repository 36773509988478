<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="编码">
              <a-input v-model="queryParam.code" placeholder="请输入完整的编码" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="原产地">
              <a-select
                v-model="queryParam.original_place"
                placeholder="选择原产地"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="海关编码">
              <a-input v-model="queryParam.hs_code" placeholder="请输入海关编码" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="是否激活">
              <a-switch v-model="queryParam.is_active" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 60px; max-width: 70px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleWithGoods(record)">关联商品</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" v-if="false" />
          <a-popconfirm
            v-if="false"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/goods/modules/CreateForm'
import { goods_create, goods_delete, goods_list } from '@/api/c_wms_goods'
import { Base64 } from 'js-base64'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          dataIndex: 'serial'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '英文名称',
          align: 'center',
          dataIndex: 'en_name',
          ellipsis: true
        },
        {
          title: '编码',
          align: 'center',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: '原产地',
          align: 'center',
          ellipsis: true,
          dataIndex: 'original_place',
          customRender: text => this.$Dictionaries.country[text] || '无'
        },
        {
          title: '海关编码',
          align: 'center',
          dataIndex: 'hs_code'
        },
        {
          title: '单位',
          align: 'center',
          dataIndex: 'unit'
        },
        {
          title: '开启保质期',
          align: 'center',
          width: 100,
          dataIndex: 'open_self_life',
          customRender: (text) => text === true ? '是' : '否'
        },
        {
          title: '保质期',
          align: 'center',
          customRender: (text, record) => {
            if (record.open_self_life) {
              const time = `${record.shelf_life} ${this.$Dictionaries.shelf_life_unit[record.shelf_life_unit]}` || '无'
              return time
            } else {
              return '-'
            }
          }
        },
        {
          title: '关税税率',
          align: 'center',
          dataIndex: 'tariff_rate',
          customRender: (text) => `${Math.round(text * 100)}%`
        },
        {
          title: '消费税',
          align: 'center',
          dataIndex: 'consumption_tax_rate',
          customRender: (text) => `${Math.round(text * 100)}%`
        },
        {
          title: '增值税',
          align: 'center',
          dataIndex: 'value_added_tax_rate',
          customRender: (text) => `${Math.round(text * 100)}%`
        },
        {
          title: '是否激活',
          align: 'center',
          dataIndex: 'is_active',
          customRender: (text) => text === true ? '是' : '否'
        },
        {
          title: '入库总量',
          align: 'center',
          dataIndex: 'total_count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '备注',
          align: 'center',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    filterOption (input, option) {
      // console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleWithGoods (record) {
      // this.$router.push('/wx/wms/commodity/' + record.id)
      this.$router.push({ path: '/zb/wms/commodity/' + record.id, query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/zb/wms/goods/' + record.id })
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      console.log(record.id)
      goods_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.tariff_rate = (values.tariff_rate / 100).toFixed(2)
          values.consumption_tax_rate = (values.consumption_tax_rate / 100).toFixed(2)
          values.value_added_tax_rate = (values.value_added_tax_rate / 100).toFixed(2)
          goods_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
