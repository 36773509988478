<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="序号" >
              <a-input-number :min="1" v-decorator="['serial', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="" >
              <span style="margin-left: 30px">提示：序号小的在前边</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="货品名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="英文名称" >
              <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="货品编码" >
              <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="海关编码" >
              <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="货品单位" >
              <a-input v-decorator="['unit', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="原产地" >
              <a-select
                v-decorator="[
                  'original_place',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="关税税率" >
              <a-input-number
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                v-decorator="['tariff_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="消费税" >
              <a-input-number
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                v-decorator="['consumption_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="增值税" >
              <a-input-number
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开启保质期" >
              <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openSelfLife"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="保质期" v-if="open_self_life">
              <a-input-number v-decorator="['shelf_life', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="保质期单位" v-if="open_self_life">
              <a-select
                v-decorator="[
                  'shelf_life_unit',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                style="width: 90px"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="备注" >
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :auto-size="{ minRows: 3, maxRows: 6 }"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list_id } from '@/api/c_wms_warehouse'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_warehouse_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      wms_warehouses: [],
      open_self_life: false
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.wmsWarehouseList()
  },
  methods: {
    openSelfLife (value) {
      console.log(value)
      this.open_self_life = value
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    wmsWarehouseList () {
      warehouse_list_id().then(res => {
        this.wms_warehouses = res.data
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
